<template>
  <v-dialog
    v-model="dialog"
    :persistent="persistent"
    max-width="500"
    :overlay-opacity="0.95"
    content-class="rounded-xl"
    scrollable
  >
    <v-card rounded="xl" class=" pb-2" light>
      <v-card-title class="d-block ">
        <div class="float-right d-flex align-center gap-2">
          <v-tooltip v-if="session.isolated" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
            </template>
            <small class="d-block">Sessão Isolada</small>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">{{
                speedComputed.icon
              }}</v-icon>
            </template>
            <small class="d-block"> Velocidade de leitura</small>
            <b class="d-block text-center">{{ speedComputed.text }}</b>
          </v-tooltip>

          <v-btn x-small icon @click="logout">
            <v-icon small>mdi-logout</v-icon>
          </v-btn>
        </div>
        <h5 class="black--text mb-0">Orientações</h5>
      </v-card-title>
      <v-card-text >
        <base-md-viewer
          class="black--text"
          :value="session.guidelines || ''"
          holder="guidelines-description"
        >
        </base-md-viewer>
        <template v-if="session.approvalCondition">
          <h6 class="mb-1">Condições para aprovação:</h6>
          {{ session.approvalCondition }}
        </template>
        <v-divider class="my-4"></v-divider>
        <div class="d-flex flex-column gap-2">
          <h6 class="mb-0">Ingressos disponíveis:</h6>
          <v-alert v-if="period" color="info" text class="mb-0" dense>
            Apenas ingressos do {{ period.name }}
            <p class="mb-0 text-12">
              {{ period.startDate | date("DD/MM HH:mm") }}
              até
              {{ period.endDate | date("DD/MM HH:mm") }}
            </p>
          </v-alert>
          <v-card
            outlined
            class="pa-2"
            v-for="tg in Object.values(ticketGroups)"
            :key="tg.id"
          >
            <h6 class="font-weight-medium">{{ tg.name }}</h6>
            <div class="d-flex flex-wrap gap-2">
              <v-chip v-for="tb in tg.TicketBlocks" :key="tb.id" small label>
                {{ tb.name }}
              </v-chip>
            </div>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions class="pb-0">
        <v-btn
          :loading="persistent"
          :disabled="persistent"
          @click="close"
          text
          block
          large
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ScannerGuideline",
  data: () => ({
    dialog: false,
    persistent: false,
    interval: null,
  }),
  methods: {
    logout() {
      this.$router.push({
        name: "staff.scanner",
        query: { autoConnect: false },
      });
    },
    open(persist = false) {
      this.dialog = true;
      this.persistent = !!persist;
      if (persist) {
        this.interval = setInterval(() => {
          this.persistent = false;
        }, persist);
      }
    },
    close(force = false) {
      if (this.persistent && !force) return;
      this.dialog = false;
      clearInterval(this.interval);
    },
  },
  computed: {
    ticketGroups() {
      return this.session?.TicketBlocks.reduce((acc, block) => {
        const ticketGroup = block.TicketGroup;
        if (!acc[ticketGroup.id]) {
          acc[ticketGroup.id] = {
            ...ticketGroup,
            TicketBlocks: [],
          };
        }
        acc[ticketGroup.id].TicketBlocks.push(block);
        return acc;
      }, {});
    },
    speedComputed() {
      const speed = this.session?.speed || 0;
      const speeds = {
        1500: { text: "Muito rápida", icon: "mdi-rocket-launch" },
        2500: { text: "Rápida", icon: "mdi-speedometer" },
        4000: { text: "Normal", icon: "mdi-speedometer-medium" },
        6000: { text: "Lenta", icon: "mdi-speedometer-slow" },
        8000: { text: "Muito lenta", icon: "mdi-snail" },
      };

      for (const speedy in speeds) {
        if (speed <= speedy) return speeds[speedy];
      }

      return { text: "Muito lenta", icon: "mdi-speedometer-slow" };
    },
  },
  mounted() {
    this.$root.$on("guideline", this.open);
    this.$root.$on("close:guideline", () => this.close(true));
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    period: {
      type: Object,
    },
  },
};
</script>

<style></style>
